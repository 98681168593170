<template>
    <div class='bodyAnalysis'>
        <div class="bodyContent" v-for="(item, index) in bodyData" :key="index">
            <div class='analysisHeader'>
                <div class="iconimg">
                    <img class="img" :src="iconimg" alt="">
                </div>
                <div class="Title">
                    <div class="text">{{ item.Title }}</div>
                    <div class="text">ANALYSIS OF SYSTEMIC ORGAN FUNCTION AND RESERVE CAPACITY</div>
                </div>
                <div class="waveImg">
                    <img class="img" :src="waveImg" alt="">
                </div>
            </div>
            <div class="content">
                <div class="head">
                    主靶向器官及问题——{{ item.subtitle }}
                </div>
                <div class="body">
                    <img class="img" :src="body">
                    <div class="left">
                        <div class="title" :ref="`secondary${index}`">
                          <div :style="'height:'+115+'px;display: flex;flex-direction: column;justify-content: center;line-height: 14px;'">
                            <div v-for="(item,index) in  '副靶向器官/问题'" class="title_title">
                              <div v-if="item=='/'" style="transform: rotateZ(45deg);">
                                {{item}}
                              </div>
                             <div v-if="item!='/'">
                               {{item}}
                             </div>
                            </div>
                          </div>

                        </div>
                        <div class="diseaseArr">
                            <div class="disease" :ref="`vice${y}${index}`" v-for="(val, y) in item.secondary" :key="y">

                                <div v-if="val.display != 'none'" class="diseaseItem"
                                    :style="{ 'backgroundColor': val.bagColor }">
                                    <div>{{ val.Title }}</div>
                                    <div class="itemContent" v-if="val.Content.length > 0">
                                        <div v-for="(el, i) in val.Content" :key="i">{{ el.ShowIndex }}</div>
                                    </div>
                                </div>
                                <div class="bar"
                                    :style="{ 'height': `${val.barHeight}px`, 'top': val.top, 'bottom': val.bottom }"></div>
                            </div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="title" :ref="`factorFa${index}`">
                          <div :style="'height:'+60+'px;display: flex;flex-direction: column;justify-content: center;line-height: 14px;'">
                          <div v-for="(item,index) in  '相关因素'" class="title_title">
                           {{item}}
                          </div>
                          </div>
                        </div>
                        <div class="diseaseArr">
                            <div class="disease" :id="'factor'+y+index" :ref="`factor${y}${index}`" v-for="(val, y) in item.factors" :key="y">

                                <div class="diseaseItem" :style="{ 'backgroundColor': val.bagColor }">
                                    <div>{{ val.Title }}</div>
                                    <div class="itemContent" v-if="val.Content.length > 0">
                                        <div v-for="(el, i) in val.Content" :key="i">{{ el.ShowIndex }}</div>
                                    </div>
                                </div>
                                <div class="bar"
                                    :style="{ 'height': `${val.barHeight}px`, 'top': val.top, 'bottom': val.bottom }"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import { GetMatrixList } from '@/api/index'
import { sortArr, unique } from '@/util/validate'

export default {
  components: {

  },
  data () {
    return {
      title: '内分泌及相关因素风险分析',
      logo: `${this.$imgDomain}/www/HealthingCore/bodylogo.png`,
      body: `${this.$imgDomain}/www/HealthingCore/bodymap.png`,
      iconimg: `${this.$imgDomain}/www/HealthingCore/icon11.png`,
      waveImg: `${this.$imgDomain}/www/HealthingCore/icon6.png`,
      bodyData: []
    }
  },
  props: {

  },
  created () {
    // 1.小程序我的页面重构(完成)
    // 2.小程序首页逻辑优化
    // 3.H5矩阵图逻辑优化
  },
  mounted () {
    this.getMatrixDataList()
  },
  methods: {
    getMatrixDataList () {
      const autoId = this.$query('autoId')
      // const autoId = '28023'
      GetMatrixList({ autoId }).then(res => {
        const { data } = res
        if (data) {
          data.forEach(item => {
            if (item.Title.indexOf('荷尔蒙') != -1) {
              item.subtitle = '荷尔蒙及相关因素分析'
              item.Title = '荷尔蒙'
            } else {
              item.subtitle = item.Title.split('及')[0]
            }

            item.Content.map(el => {
              // console.log('el',el)
              // if(el.CaseValue>=23.9){
              //     //黄色
              //     el.bagColor='#000'
              // }else if(el.CaseValue<=18.5){
              //     //黄色
              //     el.bagColor='#000'
              // }else{
              // }
              if (el.CaseValue.indexOf('%') != -1) {
                el.Percentage = Math.abs(Number(el.CaseValue.split('%')[0]))
              }
            })
            const sortData = sortArr(item.Content, 'MatrixType')
            sortData.forEach(vla => {
              const uniqueData = sortArr(vla, 'ProjectName')
              const newData = []
              uniqueData.map(el => {
                newData.push(unique(el, 'ShowIndex'))
              })

              if (newData[0][0].MatrixType.indexOf('副靶向器官') != -1) {
                item.secondary = newData
              } else {
                item.factors = newData
              }
            })
          })

          // 二次处理数据
          this.secondaryTreatment(data)
        }
      })
    },
    secondaryTreatment (data) {
      console.log(this.$refs)
      console.log('data', data)
      const newData = []
      data.forEach(item => {
        const everyEdition = {
          title: item.Title,
          subtitle: item.subtitle,
          secondary: [],
          factors: []
        }
        // 副靶向数据处理
        // console.log(item.secondary,"item.secondary")
        // return
        if (item.secondary) {
          item.secondary.forEach(el => {
            const heanldData = {}
            // 筛选病理值0.9以下的
            const filterArr = el.filter(ops => {
              heanldData.Title = ops.ProjectName
              heanldData.value1 = ops.CaseValue
              return ops.CaseValue < 0.9
            })
            // console.log('filterArr', filterArr)
            if (el.ProjectName == '瘦弱') {
              // console.log('el',el)
            }
            // 个别数据处理
            if (heanldData.Title == '瘦弱' || heanldData.titTitlele == '肥胖') {
              heanldData.Content = []
              if (heanldData.value1 >= 23.9 || heanldData.value1 < 18.5) {
                heanldData.bagColor = '#ebe080'
              } else {
                // heanldData.display='none';
                // console.log('heanldData', heanldData.value1)
              }
            } else {
              heanldData.Content = filterArr
              // console.log('heanldData2',heanldData.Content)
            }
            // console.log('heanldData.Content',heanldData.Content)
            // 程度颜色
            const minMathData = Math.min.apply(Math, filterArr.map(key => key.CaseValue))
            const sclerodItme = filterArr.find(key => minMathData == key.CaseValue)
            if (heanldData.Content.length > 0) {
              if (sclerodItme.CaseValue <= 0.425) {
                heanldData.bagColor = '#A12924'
              } else if (sclerodItme.CaseValue > 0.425 && sclerodItme.CaseValue < 0.9) {
                heanldData.bagColor = '#E8E080'
              } else {
                heanldData.bagColor = '#71A241'
              }
              if (sclerodItme.Title == '瘦弱' && sclerodItme.CaseValue <= 18.5) {
                heanldData.bagColor = '#000'
              } else if (sclerodItme.Title == '肥胖' && sclerodItme.CaseValue > 23.9) {
                // console.log("999999")
                heanldData.bagColor = '#000'
              }
            } else {
              // console.log('sclerodItme',sclerodItme)
              if (heanldData.Title == '瘦弱' || heanldData.Title == '肥胖') {
                if (heanldData.value1 >= 23.9 || heanldData.value1 < 18.5) {
                  heanldData.bagColor = '#ebe080'
                  // console.log(heanldData.value1)
                } else {
                  heanldData.bagColor = '#E8E080'
                  // heanldData.displsay='none';
                }
              } else {
                heanldData.bagColor = '#71A241'
              }

              // console.log('heanldData',heanldData)
            }

            everyEdition.secondary.push(heanldData)
          })
        }

        // 相关因素数据处理
        if (item.factors) {
          // 相关因素
          item.factors.forEach(el => {
            const heanldData = {}
            // console.log(el,'FactorsFactorsFactors')
            // return
            // console.log(el,'el')
            // return
            const filterArr = el.filter((ops, index) => {
              // console.log(filterArr,'filterArr')
              heanldData.Title = ops.ProjectName
              if (ops.Percentage) {
                if (index < 3) {
                  return ops.Percentage > 35
                }
              } else {
                return ops.CaseValue < 0.9
              }
            })
            heanldData.Content = filterArr

            if (heanldData.Content.length > 0) {
              heanldData.bagColor = ''
              const minMathData = Math.min.apply(Math, filterArr.map(key => key.CaseValue))
              const sclerodItme = el.find(key => minMathData == key.CaseValue)

              if (sclerodItme) {
                // console.log(sclerodItme,'sclerodItme')
                if (sclerodItme.CaseValue <= 0.425) {
                  heanldData.bagColor = '#A12924'
                } else if (sclerodItme.CaseValue > 0.425 && sclerodItme.CaseValue < 0.9) {
                  heanldData.bagColor = '#E8E080'
                } else {
                  heanldData.bagColor = '#71A241'
                }
              } else {
                const minMathDataNum = Math.min.apply(Math, filterArr.map(key => key.Percentage))
                // console.log(minMathDataNum,'minMathDataNum')
                const sclerodItmeNum = el.find(key => minMathDataNum == key.Percentage)

                if (sclerodItmeNum.Percentage > 35) {
                  heanldData.bagColor = '#E8E080'// 黄色
                } else {
                  heanldData.bagColor = '#71A241'// 绿色
                }
              }
            } else {
              heanldData.bagColor = '#71A241'
            }

            everyEdition.factors.push(heanldData)
          })
        }

        newData.push(everyEdition)
      })
      console.log(newData, '+++')
      this.bodyData = newData
      console.log(this.bodyData, '-----')
      this.$nextTick(() => {
        this.domDistanceProcessing(this.bodyData)
      })
      console.log(this.bodyData, 'bodyDatabodyData')
    },
    domDistanceProcessing (data) {
      data.map((item, i) => {
        const secondaryTop = this.$refs[`secondary${i}`][0].offsetTop + 87
        const factorFaTop = this.$refs[`factorFa${i}`][0].offsetTop + 87
        // 副靶向数据
        item.secondary.forEach((item, index) => {
          // console.log(item.secondary)
          let calculated = 0
          const viceItemTop = this.$refs[`vice${index}${i}`][0].offsetTop + 25
          if (viceItemTop < secondaryTop) {
            // 在中心线上方
            item.top = '25px'
            calculated = secondaryTop - viceItemTop
          } else {
            // 在中心线下方 或者 中间
            item.bottom = '25px'
            calculated = viceItemTop - secondaryTop
          }
          item.barHeight = Math.abs(calculated)
        })
        // 相关因素处理
        item.factors.forEach((item, index) => {
          var that = this
          let calculated = 0
          const factorsTop = that.$refs[`factor${index}${i}`][0].offsetTop + 25
          if (factorsTop < factorFaTop) {
            // 在中心线上方
            item.top = '25px'
            calculated = factorFaTop - factorsTop
          } else {
            // 在中心线下方 或者 中间
            item.bottom = '25px'
            calculated = factorsTop - factorFaTop
          }

          item.barHeight = Math.abs(calculated)
        })
      })

      this.$forceUpdate()
    }
  },
  watch: {

  },
  computed: {

  }
}
</script>
<style lang='scss' scoped>
.bodyAnalysis {
    width: 592.28px;

    .bodyContent {
        height: 860px;
        padding-top: 20px;
        margin-top: 40px;

        .analysisHeader {
            min-height: 50px;
            width: 100%;
            border-radius: 15px 15px 0 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            overflow: hidden;
            color: #fff;
            background: url('https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/bag3.png');
            background-repeat: no-repeat;
            background-position: center;

            .iconimg {
                width: 80px;
            }

            .waveImg {
                width: 30px;
                height: 30px;
            }

            .Title {
                width: calc(100% - 130px);
                padding-left: 10px;
                border-left: 2px solid #fff;
                margin: 0 10px;

                .text {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }

        .content {
            height: calc(100% - 60px);
            display: flex;
            justify-content: center;
            align-items: center;
            background: #F5F5F5;
            border-radius: 0 0 15px 15px;
            margin-top: 5px;
            position: relative;

            .head {
                width: 90%;
                height: 50px;
                position: absolute;
                top: 5%;
                background: url('https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/bag3.png');
                background-repeat: no-repeat;
                background-position: center;
                font-size: 18px;
                color: #585757;
                font-weight: 700;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .body {
                width: 22%;
                position: relative;

                .left,
                .right {
                    height: 600px;
                    position: absolute;
                    bottom: -35%;
                    display: flex;
                    align-items: center;
                   .title {
                     position: relative;
                      display: flex;
                      align-items: center;
                        height: 175px;
                        width: 25px;
                        background: #585757;
                        color: #fff;
                        border-radius: 3px;
                        z-index: 999;
                        font-size: 14px;
                        justify-content: center;

                        .title_title{
                          div{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          }
                        }
                    }
                    .diseaseArr {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        height: 100%;

                        .disease {
                            position: relative;
                        }

                        .diseaseItem {
                            width: 50px;
                            height: 50px;
                            border-radius: 25px;
                            color: #fff;
                            padding: 0 5px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            flex-wrap: wrap;
                            position: relative;

                            .itemContent {
                                width: 100px;
                                text-align: start;
                                position: absolute;
                                color: #585757;
                                border: 1px dashed #585757;
                                padding: 0 3px;
                            }
                        }

                        .bar {
                            position: absolute;
                            width: 1px;
                            background: #585757;
                        }

                    }
                }

                .left {
                    left: -105px;
                    flex-direction: row-reverse;

                    .title {
                        margin-left: 20px;
                    }

                    .diseaseArr {
                        .diseaseItem::after {
                            content: "";
                            width: 32px;
                            height: 1px;
                            background: #585757;
                            position: absolute;
                            right: -32px;
                        }

                        .diseaseItem {
                            .itemContent {
                                left: -110px;
                            }
                        }

                        .bar {
                            right: -32px;
                        }
                    }
                }

                .right {
                    right: -105px;

                    .title {
                        margin-right: 20px;
                    }

                    .diseaseArr {
                        .diseaseItem::after {
                            content: "";
                            width: 32px;
                            height: 1px;
                            background: #585757;
                            position: absolute;
                            left: -32px;
                        }

                        .diseaseItem {
                            .itemContent {
                                right: -110px;
                            }
                        }

                        .bar {
                            left: -32px;
                        }
                    }
                }
            }
        }
    }

    .bodyContent:first-child {
        margin-top: 0;
    }
}
</style>
